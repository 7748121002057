import * as React from "react";

export type ProductContainerProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
};

export function FadeInContainer({ children }: ProductContainerProps) {
  const domRef = React.useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        observer.unobserve(domRef.current as Element);
      }
    });

    observer.observe(domRef.current as Element);

    return () => observer.disconnect();
  }, []);

  return (
    <section
      ref={domRef}
      className={`fade-section ${isVisible ? " is-visible" : ""} `}
    >
      {children}
    </section>
  );
}

export default FadeInContainer;
